import { addMethod, number as NumberSchema, Schema, setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'Value is required',
  },
  number: {
    min: 'Value must at least ${min}',
    max: 'Value must be at most ${max}',
  },
});

addMethod(Schema, 'debug', function (prefix?: string) {
  return this.transform((value, originalValue, ctx) => {
    console.log(prefix === undefined ? `Yup Debug: ` : `Yup Debug: ${prefix}: `, {
      value,
      originalValue,
      ctx,
    });
    return value;
  });
});

addMethod(NumberSchema, 'nanAsNull', function () {
  return this.transform((value) => (isNaN(value) ? null : value));
});

addMethod(NumberSchema, 'nanAsUndefined', function () {
  return this.transform((value) => (isNaN(value) ? undefined : value));
});
