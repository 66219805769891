import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

interface ShowHeaderMenuContextType {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const ShowHeaderMenuContext = createContext<ShowHeaderMenuContextType | undefined>(undefined);

export const ShowHeaderMenuContextProvider = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useState<boolean>(true);
  return (
    <ShowHeaderMenuContext.Provider
      value={{
        show,
        setShow,
      }}
    >
      {children}
    </ShowHeaderMenuContext.Provider>
  );
};

export const useShowHeaderMenu = (): ShowHeaderMenuContextType => {
  const context = useContext(ShowHeaderMenuContext);
  if (!context) {
    throw new Error('useShowHeaderMenu must be used within a BooleanProvider');
  }
  return context;
};
