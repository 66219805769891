import '@/helpers/yup-extensions';

// Currency values are stored as an integer representing 6 decimal places,
// so a divisor of 1_000_000 take 245240000 and convert it to 245.24
window.CURRENCY_PRECISION_DIVISOR = 1_000_000;

/**
 * Sentry Setup
 */
import * as Sentry from '@sentry/react';

if (window.appSentryConfig)
  Sentry.init({
    dsn: window.appSentryConfig.dsn,
    integrations: [
      Sentry.browserTracingIntegration({
        traceFetch: true,
        traceXHR: true,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: window.appSentryConfig.tracesSampleRate,
    tracePropagationTargets: window.appSentryConfig.tracePropagationTargets,
    // Session Replay
    replaysSessionSampleRate: window.appSentryConfig.replaysSessionSampleRate,
    replaysOnErrorSampleRate: window.appSentryConfig.replaysOnErrorSampleRate,
  });

import { toast } from 'sonner';

window.toast = toast;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';

if (window.appBroadcastingConfig['broadcaster'] !== 'log') {
  window.Pusher = Pusher;
  window.Echo = new Echo(window.appBroadcastingConfig);
}
