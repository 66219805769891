import { TooltipProvider } from '@/Components/Tooltip';
import cls from '@/helpers/cls';
import { ShowHeaderMenuContextProvider } from '@/helpers/showHeaderMenu';
import { createInertiaApp } from '@inertiajs/react';
import { CheckCircle, Info, Warning, WarningCircle } from '@phosphor-icons/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createRoot } from 'react-dom/client';
import { Toaster } from 'sonner';
import '../css/app.css';
import './bootstrap';

const toastSettings = {
  icons: {
    success: <CheckCircle weight="fill" className="size-5 text-green-500" />,
    error: <WarningCircle weight="fill" className="size-5 text-red-500" />,
    info: <Info className="size-5" />,
    warning: <Warning weight="fill" className="size-5 text-yellow-500" />,
  },
  className: 'whitespace-pre-line',
  cn: cls,
  pauseWhenPageIsHidden: true,
} satisfies Parameters<typeof Toaster>[0];

const queryClient = new QueryClient();

// noinspection JSIgnoredPromiseFromCall
createInertiaApp({
  title: (title) => `${title} - ${import.meta.env.VITE_APP_NAME}`,
  page: window.inertiaInitialPageData,
  progress: {
    color: '#4b5563',
  },
  resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
  setup: ({ el, App, props }) => {
    createRoot(el).render(
      <QueryClientProvider client={queryClient}>
        <TooltipProvider>
          <ShowHeaderMenuContextProvider>
            <Toaster {...toastSettings} />
            <App {...props} />
          </ShowHeaderMenuContextProvider>
        </TooltipProvider>
      </QueryClientProvider>,
    );
  },
});
